@import "../theme/core/variables";

.TalentMini {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
    
    @include breakpoint('xl', down) {
        font-size: 0.875rem;
    }
    
    .TalentImg {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: $borderRadius;
    }
}