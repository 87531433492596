@import "../theme/core/variables";

.TalentCard {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: $colorWhite;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.10);
    border-radius: $borderRadius * 2;
    padding: 0.5rem;
    margin-bottom: 0.5rem;

    &>div {
        margin: 0 0.75rem;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    .primary {
        font-size: $fontSize20;
        font-weight: $fontWeightBold;
    }

    .label {
        font-size: $fontSize12;
        color: $colorGray6;
        text-transform: uppercase;
    }

    .core {
        flex: 0 0 148px;
        overflow: hidden;

        &>div {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .talentName {
            font-style: italic;
            margin: 0.375rem 0;
        }
    }

    .price {
        flex: 0 1 auto;

        .customBid {
            display: inline !important;
            width: unset !important;
            max-width: 5rem;
            -moz-appearance: textfield; // Firefox

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0; // <-- Apparently some margin are still there even though it's hidden
            }

            &::placeholder {
                color: $colorGray5;
            }
        }

    }

    // .stats {
    //     flex: 0 0 auto;

    //     &.stats-row {
    //         display: flex;
    //         flex-wrap: wrap;

    //         &>.stats-item {
    //             &:not(:last-of-type) {
    //                 flex: 1 0 auto;
    //                 margin-right: 1rem;
    //             }
    //         }
    //     }

    //     .statsItem {
    //         display: flex;
    //         align-items: center;
    //         margin-top: 0.5rem;
    //         margin-bottom: 0.5rem;
    //     }
    // }

    .contentTags {
        flex: 1 1;

        .contentTag {
            font-size: $fontSize12;
            display: inline-block;
            margin: 0.375rem;
            padding: 0.25rem;
            background-color: rgba($colorBlue, 0.4);
            border-radius: 0.25rem;
            text-transform: uppercase;
        }

        .contentTagBlack {
            background-color: $colorBlack;
            color: $colorBlue;
        }
    }

    input[type="checkbox"].talentCheck {
        appearance: none;
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        // margin-right: 0.75rem;

        &:after {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: calc(1.5rem + 2px);
            height: calc(1.5rem + 2px);
            border: 1px solid #e8e8e8;
            border-radius: 3px;
            background-color: #fff;
            position: absolute;
            content: ' ';
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:disabled {
            &:after {
                cursor: not-allowed;
            }
        }

        &:checked:after {
            content: ' ';
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.055 6.867a.54.54 0 0 1 .07.211.54.54 0 0 1-.07.211L10.3 18.54c-.14.14-.246.211-.317.211-.117 0-.234-.059-.351-.176l-4.957-4.781-.106-.105a.54.54 0 0 1-.07-.211c0-.024.023-.082.07-.176l.07-.07a69.366 69.366 0 0 1 1.548-1.618c.14-.14.234-.21.28-.21.095 0 .212.07.352.21l2.813 2.742 7.031-9.035c.047-.047.117-.07.211-.07.07 0 .152.023.246.07l1.934 1.547Z' fill='%2362EDFF' fill-rule='nonzero'/%3E%3C/svg%3E");
            background-color: rgba($colorBlue, 0.1);
        }
    }

    // img,
    .TalentImg {
        width: 80px;
        height: 80px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: $borderRadius;
    }
}