@import "../theme/core/variables";

.TalentFilter {
    // padding: 0.25rem 0.5rem;
    // display: flex;
    // align-items: center;
    // justify-content: flex-end;
    // flex-wrap: wrap;
    border: 1px solid $colorGray3;
    background-color: $colorWhite;
    // border-radius: $borderRadius;
    border-radius: 0.5rem;

    .TalentFilterRow {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        padding: 0 0.75rem;

        &:not(:first-of-type) {
            border-top: 1px solid $colorGray3;
        }

        .ChipSelect,
        .ChipRange {
            margin: 0.5rem 0;
        }
    }

    #TalentSearch {
        border: none;
        flex: 1 1 auto;
        width: unset;
        height: 2rem;
        margin: 0.625rem 0;
    }
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 2rem);
    max-width: 600px;
    background-color: white;
    border: '2px solid #000';
    box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

    .TalentFilterHeader {
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.25rem 0.5rem;
        background-color: $colorGray1;
        border-bottom: 1px solid $colorGray3;
    }

    .TalentFilterControl {
        margin-bottom: 1rem;
    }
}

.TalentFilterChip {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    height: 2rem;
    font-size: $fontSize14;
    line-height: 1;
    padding: 0.5rem 1rem;
    background-color: rgba($colorBlue, 0.4);
    border-radius: 1rem;
    font-weight: $fontWeightBold;
    cursor: pointer;

    // @include breakpoint(md, down) {
    //     display: none;
    // }

    &:hover {
        background-color: $colorBlack;
        color: $colorBlue;
    }
}

// .MuiTypography-root.Mui-disabled {
//     filter: grayscale(100%);
// }