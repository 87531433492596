@import "../theme/core/variables";

.LayoutOnboarding {
    padding: 2rem 1rem;
    width: 100vw;
    min-height: calc(100vh - 5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $colorGray1;
    text-align: center;

    img {
        display: block;
        width: 200px;
        height: auto;
        margin: 0 auto 2rem auto;
        // margin-bottom: 2rem;
    }

    main {
        min-height: 286px; // Height of tallest main section, to prevent page jumps
        width: 100%;
    }

    .card {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 20rem;
        // max-width: calc(100% - 4rem);
        max-width: 100%;
    }
}

.NewClient {
    .LayoutOnboarding {
        .card {
            width: 25rem;
        }
    }
}

.EditTalent {
    .LayoutOnboarding {
        .card {
            width: 25rem;
        }
    }
}

footer {
    background-color: $colorBlack;
    color: $colorWhite;
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}