@import "../theme/core/variables";

.ChipRadioGroup {
    $chipColor: $colorBlue;
    flex-direction: row !important;

    &.red {
        $chipColor: $colorRed;
    }

    .MuiFormControlLabel-root {
        border: 1px solid $chipColor;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.5rem 0.25rem;
        height: 2rem;
        font-size: $fontSize14;
        line-height: 1;
        background-color: $colorWhite;
        border-radius: 1rem;
        cursor: pointer;
        border: 1px solid $chipColor;
        position: relative;
        font-family: $fontFamilyPrimary;
        padding: 0 0.75rem;
        width: fit-content;

        &:hover {
            background-color: rgba($chipColor, 0.2);
        }
        
        &.checked {
            background-color: rgba($chipColor, 0.2);
                
                &:hover {
                    background-color: rgba($chipColor, 0.4);
                }
        }
    }

    .MuiRadio-root {
        padding: 0;
        color: $colorBlack !important;
    }
}