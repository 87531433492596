@import "../theme/core/variables";

.BookingSummary {
    max-width: 25rem;
    margin: 0 auto;
    
    .BookingSummaryStats {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 20rem;
    }

    hr {
        border-top: 1px solid $colorGray3;
        border-right: none;
        border-bottom: none;
        border-left: none;
        margin: 2rem auto;
    }

    .BookingSummaryList {
        width: 100%;
        margin: 0 auto;
    }

}