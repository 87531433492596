@import "../theme/core/variables";

.SignIn {
    .logo-font {
        font-family: $fontFamilySecondary;
        font-weight: $fontWeightBold;
        font-size: $fontSize32;
        text-transform: uppercase;
        color: $colorBlack;
        text-decoration: none;
        text-shadow: none;

        @include breakpoint(sm) {
            font-size: $fontSize48;
        }
    }

    // .change-mode-link {
    //     display: inline-block;
    //     color: $colorBlue;
    //     border-bottom: 1px dashed $colorBlue;
    //     cursor: pointer;

    //     &:hover,
    //     &:focus,
    //     &:active {
    //         color: $colorRed;
    //         border-bottom: 1px dashed $colorRed;
    //     }
    // }

    input:not(:last-of-type) {
        margin-bottom: 1rem;
    }
}