@import "../theme/core/variables";

.ChipSelect {
    .Chip {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        height: 2rem;
        font-size: $fontSize14;
        line-height: 1;
        background-color: $colorWhite;
        border-radius: 1rem;
        font-weight: $fontWeightBold;
        cursor: pointer;
        border: 1px solid $colorBlue;
        position: relative;

        &:not(:active):hover {
            background-color: rgba($colorBlue, 0.2);
        }

        .ChipLabel {
            padding: 0 0.25rem 0 0.75rem;
            height: 100%;
            border-radius: 1rem;
            display: flex;
            align-items: center;
        }

        &.active {
            background-color: rgba($colorBlue, 0.2);

            .ChipLabel {
                padding: 0 2.25rem 0 0.75rem;

                &:hover {
                    background-color: rgba($colorBlue, 0.3);
                }
            }

            .MuiSvgIcon-root.chip-select-icon {
                position: absolute;
                right: 0;
                background-position: center;

                &:hover {
                    background: $colorWhite radial-gradient(circle, transparent 1%, $colorWhite 1%) center/0%;
                }

                &.active {
                    background-size: 15000%;
                    background-color: $colorBlue;
                    transition: background 0.6s ease-in;
                }
            }
        }

        .MuiSvgIcon-root.chip-select-icon {
            box-sizing: content-box;
            padding: 7px 0.5rem;
            border-radius: 1rem;
        }
    }
}