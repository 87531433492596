@import "../theme/core/variables";

.BookingNavigation {
    width: 100%;
    height: 5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    border-top: 1px solid $colorGray3;
    background-color: $colorWhite;
    z-index: 100;

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .BookingStepImg {
        width: 5rem;
        height: 5rem;
    }

    .BookingStepBack {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}