@import "../theme/core/variables";

.CampaignsPage {
    padding: 1rem;
    max-width: 32rem;
    margin: 0 auto;

    .card {
        margin-bottom: 1rem;
        cursor: pointer;
    }

    .label {
        font-size: $fontSize12;
        color: $colorGray6;
        text-transform: uppercase;
    }
}