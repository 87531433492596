@import "../theme/core/variables";

.CampaignDetailsSummary {
    background-color: $colorWhite;
    font-size: $fontSize14;

    max-width: 32rem;

    &>div {
        padding: 0.5rem 0;

        &:not(:last-of-type) {
            border-bottom: 1px solid $colorGray3;
        }

        &:last-of-type {
            padding-bottom: 0;
        }
    }
}