@import "../theme/core/variables";

.Layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &.LayoutGray {
        background-color: $colorGray1;
    }

    nav {
        width: 100%;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1px solid $colorGray3;
        background-color: $colorBlue;

        .logo-font {
            font-family: $fontFamilySecondary;
            font-weight: $fontWeightBold;
            font-size: $fontSize32;
            text-transform: uppercase;
            color: $colorBlack;
            text-decoration: none;
            text-shadow: none;

            @include breakpoint(sm) {
                font-size: $fontSize48;
            }
        }

        img {
            height: 3rem;
            width: auto;
        }
    }

    main {
        min-height: calc(100vh - 10rem);
    }

    footer {
        background-color: $colorBlack;
        color: $colorWhite;
        width: 100%;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
    }
}