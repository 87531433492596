.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 1rem;
    border: 1px solid transparent;
    border-radius: calc($borderRadius / 2);
    font-weight: $fontWeightSemibold;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.25em;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
}

.btn:disabled,
.btn.disabled,
.btn.is-disabled {
    cursor: not-allowed !important;
    opacity: 0.65;
    border: none;
    box-shadow: none;
}

/* Filled Buttons */
@mixin btn-variant($color) {
    color: #fff;
    background-color: $color;
    // border-color: darken($color, 20%);

    &:hover,
    &:focus,
    &.focus,
    &:active {
        color: #fff;
        // background-color: darken($color, 5%);
        background-color: darken($color, 10%);
    }

    &:focus {
        box-shadow: 0 0 0 3px rgba($color, 0.25);
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {

        &:hover,
        &:focus,
        &.focus {
            background-color: $color;
            border-color: $color;
        }
    }
}

.btn-blue {
    @include btn-variant($colorBlue);
}

.btn-green {
    @include btn-variant($colorGreen);
}

.btn-orange {
    @include btn-variant($colorOrange);
}

.btn-red {
    @include btn-variant($colorRed);
}

.btn-gray {
    @include btn-variant($colorGray4);
}

.btn-primary {
    @include btn-variant($colorBlack);
    color: $colorBlue;
    text-transform: uppercase;

    font-family: $fontFamilySecondary;
    font-weight: $fontWeightSemibold;

    &:hover,
    &:focus,
    &.focus,
    &:active {
        background-color: $colorBlue;
        color: $colorBlack;
    }
}

.btn-silent {
    color: $colorGray9;
    background-color: $colorWhite;
    font-size: 1rem;
    font-family: $fontFamilyPrimary;
    font-weight: $fontWeightNormal;

    *[class^="icon-"] {
        background-color: $colorGray9;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:focus,
    &.focus,
    &:active {
        color: #fff;
        background-color: $colorGray5;

        *[class^="icon-"] {
            background-color: $colorWhite !important;
        }
    }
}

/* Outline Buttons */
@mixin btn-outline-variant($color) {
    color: $color;
    background-color: #fff;
    border: 1px solid $color;

    &:hover,
    &:focus,
    &.focus,
    &:active {
        color: #fff;
        background-color: $color;
    }

    &:focus {
        box-shadow: 0 0 0 3px rgba($color, 0.25);
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {

        &:hover,
        &:focus,
        &.focus {
            background-color: #fff;
            // border: 1px solid $color;
        }
    }
}

.btn-outline-blue {
    @include btn-outline-variant($colorBlue);
}

.btn-outline-green {
    @include btn-outline-variant($colorGreen);
}

.btn-outline-orange {
    @include btn-outline-variant($colorOrange);
}

.btn-outline-red {
    @include btn-outline-variant($colorRed);
}

.btn-outline-gray {
    @include btn-outline-variant($colorGray4);
}

@mixin btn-toggle-variant($color) {
    color: $color;
    background-color: #fff;
    border: 1px solid $color;

    &.is-selected {
        color: #fff;
        background-color: $color;
    }
}

.btn-toggle-blue {
    @include btn-toggle-variant($colorBlue);
}

.btn-toggle-green {
    @include btn-toggle-variant($colorGreen);
}

.btn-toggle-orange {
    @include btn-toggle-variant($colorOrange);
}

.btn-toggle-red {
    @include btn-toggle-variant($colorRed);
}

.btn-toggle-gray {
    @include btn-toggle-variant($colorGray4);
}

/* */
@mixin btn-text-variant($color) {
    color: $color;
    background-color: $colorWhite;
    font-weight: $fontWeightNormal;
    // border: 1px solid $color;
    height: unset;
    line-height: 1;

    &:hover,
    &:focus,
    &.focus,
    &:active {
        // color: #fff;
        // background-color: rgba($color, 0.25);
        color: darken($color, 10%);
        background-color: $colorWhite;
        ;
    }

    &:focus {
        // box-shadow: 0 0 0 3px rgba($color, 0.25);
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {

        &:hover,
        &:focus,
        &.focus {
            background-color: #fff;
            // border: 1px solid $color;
        }
    }
}

.btn-text-blue {
    @include btn-text-variant($colorBlue);
}

.btn-text-green {
    @include btn-text-variant($colorGreen);
}

.btn-text-orange {
    @include btn-text-variant($colorOrange);
}

.btn-text-red {
    @include btn-text-variant($colorRed);
}

.btn-text-gray {
    @include btn-text-variant($colorGray4);
}

/* sizing */
.btn-xs {
    height: 22px;
    padding: 0 0.375rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    line-height: 1;
}

.btn-sm {
    height: 32px;
    padding: 0 0.75rem;
    font-size: 0.75rem;
    font-weight: $fontWeightSemibold;
    text-transform: uppercase;
    line-height: 1;
}

.btn-lg {
    // padding: 0 1.25rem;
    // font-size: 1.25rem;
    height: 4rem;
    font-size: $fontSize18;
}

/* block */
.btn-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 1rem;
}

.btn-tag {
    font-size: $fontSize12;
    display: inline-block;
    margin: 0.375rem;
    padding: 0.25rem;
    background-color: rgba($colorBlue, 0.2);
    border-radius: 0.25rem;
    text-transform: uppercase;
    height: unset;
    color: $colorBlack !important;
    font-weight: medium;
}

.btn-link {
    background: none;
    border: none;
    text-transform: unset;
    padding: 0;
    height: unset;

    display: inline-block;
    color: darken($colorBlue, 25%);
    border-bottom: 1px dashed darken($colorBlue, 25%);
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        color: $colorRed;
        border-bottom: 1px dashed $colorRed;
    }
}

.btn-link.btn-link-red {
    color: $colorRed;
    border-bottom: 1px dashed $colorRed;

    &:hover,
    &:focus,
    &:active {
        color: darken($colorBlue, 25%);
        border-bottom: 1px dashed darken($colorBlue, 25%);
    }
}