@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

// @import url("https://use.typekit.net/sbm7dpm.css");
@font-face {
    font-family: "brandon-grotesque";
    src: url("https://use.typekit.net/af/257c86/000000000000000077359df6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),
        url("https://use.typekit.net/af/257c86/000000000000000077359df6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),
        url("https://use.typekit.net/af/257c86/000000000000000077359df6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 900;
}

// Reset
@import "./core/reset";

// Variables
@import "./core/variables";

// Core
@import "./core/breakpoints"; // responsive utils, not the breakpoint mixin!
@import "./core/flex";
@import "./core/grid";
@import "./core/icons";
@import "./core/img";
@import "./core/color";
@import "./core/typography";
@import "./core/spacing";
// @import './core/icons';
@import "./core/form";
@import "./core/button";

// Components
@import "./components/stats";

.card {
    background-color: $colorWhite;
    border-radius: $borderRadius;
    padding: 1rem;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15) !important;
}

.AppLoading {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

hr {
    border: none;
    border-top: 1px solid $colorGray3;
    border-right: none;
    border-bottom: none;
    border-left: none;
    margin: 2rem auto;
}