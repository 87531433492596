@mixin sm-max-width {
    @include breakpoint(sm) {
        max-width: 512px;
        margin-left: auto;
        margin-right: auto;
    }
}

@mixin md-max-width {
    @include breakpoint(md) {
        max-width: 688px;
        margin-left: auto;
        margin-right: auto;
    }
}

@mixin lg-max-width {
    @include breakpoint(lg) {
        max-width: 928px;
        margin-left: auto;
        margin-right: auto;
    }
}

.lg-max-width {
    @include lg-max-width;
}

@mixin xl-max-width {
    @include breakpoint(xl) {
        max-width: 1120px;
        margin-left: auto;
        margin-right: auto;
    }
}

.xl-max-width {
    @include xl-max-width;
}

@mixin full-width {
    margin-left: calc(-100vw / 2 + 100% / 2);
    margin-right: calc(-100vw / 2 + 100% / 2);
    max-width: 100vw;
}

.align-full {
    @include full-width;
}

.gap-sm {
    gap: 1.5rem;
}

.gap-md {
    gap: 3rem;
}

.gap-lg {
    gap: 6rem;
}

/* GRID!!!!!!!!! */
/* Containers are now for content w/o rows */
.row,
.container,
// .wp-block-columns,
.site-inner {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
}

.row-fluid,
.container-fluid {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    &.no-padding {
        padding: 0;
    }
}

.row,
.row-fluid,
.wp-block-columns.row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
}

@include breakpoint(sm) {

    // @media (min-width: 576px) {
    .row,
    .row-fluid,
    .wp-block-columns.row {
        gap: 1rem 2rem;
    }
}

.row,
.container,
.site-inner {
    // @include sm-max-width;
    // @include md-max-width;
    @include lg-max-width;
    @include xl-max-width;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    grid-column: span 12;
}

.col-12 {
    grid-column: span 12;
}

.col-11 {
    grid-column: span 11;
}

.col-10 {
    grid-column: span 10;
}

.col-9 {
    grid-column: span 9;
}

.col-8 {
    grid-column: span 8;
}

.col-7 {
    grid-column: span 7;
}

.col-6 {
    grid-column: span 6;
}

.col-5 {
    grid-column: span 5;
}

.col-4 {
    grid-column: span 4;
}

.col-3 {
    grid-column: span 3;
}

.col-2 {
    grid-column: span 2;
}

.col-1 {
    grid-column: span 1;
}

@include breakpoint(sm) {
    .col-sm-12 {
        grid-column: span 12;
    }

    .col-sm-11 {
        grid-column: span 11;
    }

    .col-sm-10 {
        grid-column: span 10;
    }

    .col-sm-9 {
        grid-column: span 9;
    }

    .col-sm-8 {
        grid-column: span 8;
    }

    .col-sm-7 {
        grid-column: span 7;
    }

    .col-sm-6 {
        grid-column: span 6;
    }

    .col-sm-5 {
        grid-column: span 5;
    }

    .col-sm-4 {
        grid-column: span 4;
    }

    .col-sm-3 {
        grid-column: span 3;
    }

    .col-sm-2 {
        grid-column: span 2;
    }

    .col-sm-1 {
        grid-column: span 1;
    }
}

@include breakpoint(md) {
    .col-md-12 {
        grid-column: span 12;
    }

    .col-md-11 {
        grid-column: span 11;
    }

    .col-md-10 {
        grid-column: span 10;
    }

    .col-md-9 {
        grid-column: span 9;
    }

    .col-md-8 {
        grid-column: span 8;
    }

    .col-md-7 {
        grid-column: span 7;
    }

    .col-md-6 {
        grid-column: span 6;
    }

    .col-md-5 {
        grid-column: span 5;
    }

    .col-md-4 {
        grid-column: span 4;
    }

    .col-md-3 {
        grid-column: span 3;
    }

    .col-md-2 {
        grid-column: span 2;
    }

    .col-md-1 {
        grid-column: span 1;
    }
}

@include breakpoint(lg) {
    .col-lg-12 {
        grid-column: span 12;
    }

    .col-lg-11 {
        grid-column: span 11;
    }

    .col-lg-10 {
        grid-column: span 10;
    }

    .col-lg-9 {
        grid-column: span 9;
    }

    .col-lg-8 {
        grid-column: span 8;
    }

    .col-lg-7 {
        grid-column: span 7;
    }

    .col-lg-6 {
        grid-column: span 6;
    }

    .col-lg-5 {
        grid-column: span 5;
    }

    .col-lg-4 {
        grid-column: span 4;
    }

    .col-lg-3 {
        grid-column: span 3;
    }

    .col-lg-2 {
        grid-column: span 2;
    }

    .col-lg-1 {
        grid-column: span 1;
    }
}

@include breakpoint(xl) {
    .col-xl-12 {
        grid-column: span 12;
    }

    .col-xl-11 {
        grid-column: span 11;
    }

    .col-xl-10 {
        grid-column: span 10;
    }

    .col-xl-9 {
        grid-column: span 9;
    }

    .col-xl-8 {
        grid-column: span 8;
    }

    .col-xl-7 {
        grid-column: span 7;
    }

    .col-xl-6 {
        grid-column: span 6;
    }

    .col-xl-5 {
        grid-column: span 5;
    }

    .col-xl-4 {
        grid-column: span 4;
    }

    .col-xl-3 {
        grid-column: span 3;
    }

    .col-xl-2 {
        grid-column: span 2;
    }

    .col-xl-1 {
        grid-column: span 1;
    }
}