.stats {
    flex: 0 0 auto;
    align-items: flex-start;

    &.stats-row {
        display: flex;
        // flex-wrap: wrap;

        &>.statsItem {
            // &:not(:last-child) {
            flex: 1 1 auto;
            margin-right: 1rem;
            // }
        }
    }

    .statsItem {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .label {
        font-size: $fontSize12;
        color: $colorGray6;
        text-transform: uppercase;
    }
}