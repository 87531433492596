@import "../theme/core/variables";

.NewBookingPage {

    // .RenderIfVisible:nth-of-type(1) {

    //     .NonVisible {
    //         &::after {
    //             display: block;
    //             content: 'Loading...';
    //             text-align: center;
    //         }
    //     }
    // }

    .BookingStep0 {
        padding: 1rem 1rem 3rem 1rem;
        max-width: 700px;
        margin: 0 auto;
    }

    .BookingStep1,
    .BookingStep2 {
        display: flex;

        .Main {
            padding: 1rem 1rem 3rem 1rem;
            width: 100%;

            @include breakpoint(lg) {
                display: block;
                flex: 0 0 70%;
            }
        }

        .Side {
            display: none;
            background-color: $colorWhite;
            border-left: 1px solid $colorGray3;

            @include breakpoint(lg) {
                flex: 0 0 30%;
                display: block;
                position: sticky;
                top: 0;
                height: calc(100vh - 5rem);
                overflow: auto;
                padding: 1rem;
            }
        }

        .MobileSummary {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100vh;
            padding: 1rem 1rem 5rem 1rem;
            background-color: $colorWhite;

            @include breakpoint(lg) {
                display: none;
            }

            .MobileSummaryBody {
                height: calc(100vh - 6rem);
                overflow-y: auto;
            }
        }
    }

    // .stats {
    //     flex: 0 0 auto;

    //     &.stats-row {
    //         display: flex;
    //         flex-wrap: wrap;

    //         &>.stats-item {
    //             &:not(:last-of-type) {
    //                 flex: 1 0 auto;
    //                 margin-right: 1rem;
    //             }
    //         }
    //     }

    //     .statsItem {
    //         display: flex;
    //         align-items: center;
    //         margin-top: 0.5rem;
    //         margin-bottom: 0.5rem;
    //     }
    // }

    .label {
        font-size: $fontSize12;
        color: $colorGray6;
        text-transform: uppercase;
    }

    .BookingTalentList {
        max-width: 60rem;
        margin: 0 auto;
    }

    .BookingForm {
        padding: 1rem;
        max-width: 700px;
        margin: 0 auto;

        .UserDetailsSummary {
            background-color: $colorWhite;
            border-radius: 0.25rem;
            padding: 0 0.5rem;
            border: 1px solid $colorGray3;
            font-size: $fontSize14;

            &>div {
                padding: 0.5rem 0;

                &:not(:last-of-type) {
                    border-bottom: 1px solid $colorGray3;
                }
            }
        }

        .BookingFormChange {
            color: $colorBlue;
            cursor: pointer;
            margin-left: 0.5rem;
        }

        label[for="isBatched"] {
            font-weight: $fontWeightNormal;
        }

        textarea {
            height: 150px;
        }
    }
}